import React from "react"
import { graphql } from 'gatsby'
 
import Layout from "../components/Layout"
import Seo from '../components/SEO'
import Pager from "../components/Pager"
import { Row}  from 'react-bootstrap'

import * as styles from '../styles/list-item.module.css'

import ArticlePreview from '../components/ArticlePreview'

const pfsense_List = ({ data, pageContext }) => {

    const examples = data.allMarkdownRemark.nodes;

    return (
        <Layout>
            <Seo
                title="Pfsense"
                description="pfSense - World's Most Trusted Open Source Firewall"
                keywords="pfSense, Router, Firewall, VLAN, OpenSource"
            />     
            <h1>pfSense</h1>           
                 
            <Row className={styles.list}>
                {examples.map(item => (
               <ArticlePreview key={item.id}
                   title={item.frontmatter.title}
                   link = {"/" + item.frontmatter.slug}
                   published= {item.frontmatter.published}
                   image = {"/" + item.frontmatter.image}

                />           
            ))}
            </Row>
            <Pager pageContext={pageContext} />
        </Layout>
    )
}


export const query= graphql`
query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
        filter: {fileAbsolutePath: {glob: "**/tutorials/pfsense/*"}}
        sort: {fields: frontmatter___order, order: ASC}
        skip: $skip,
        limit: $limit
        ) {
        nodes {
            frontmatter {
                slug
                title
                image
                published(formatString: "MMMM Do, YYYY")
            }
            id
        }
    }
  }

`

export default pfsense_List;